<template>
  <div>
    <Navbar page="Avaliações" link="/treinamentos" nameLink="Gestão de Treinamentos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <router-link :to="`/${route}/form`" type="button" 
        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
        class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
        focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
        focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
        rounded-lg text-sm shadow-sm hover:shadow-md w-full mr-2 md:w-72 font-semibold text-center
        mb-4
        "
      >
          <span class="inline-block mr-2">Nova avaliação</span>
      </router-link>

      <button @click="excluirSelecionadas" v-if="!$store.state.user.cliente && selecionados.length" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center mb-2 md:ml-4">
        <span class="inline-block">Arquivar selecionadas</span>
      </button>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                        <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Nome
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Perguntas
                      </th>
                      <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-3 py-3 whitespace-nowrap">
                        <div class="flex items-center">
                          <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.nome ? item.nome : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-2 py-3">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.perguntas ? item.perguntas : 0}}
                          </div> 
                        </div>
                      </td>
                      <td class="px-2 py-3 whitespace-nowrap text-sm font-medium">
                          <router-link type="button" :to="`/${route}/form/${item._id}`" class="inline-block text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mt-2 mx-2">
                              Editar
                          </router-link>
                          <router-link type="button" :to="`/${route}/corrigir/${item._id}`" class="text-white hover:bg-green-600 bg-green-500 rounded py-1 px-4 mx-1 my-1">
                            Corrigir
                          </router-link>
                          <button
                            @click="duplicar(item._id)" 
                            type="button" 
                            class="text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-4 mx-1 my-1">
                            Duplicar
                          </button>
                          <button
                            v-if="!$store.state.user.cliente"
                            @click="remove(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                            Arquivar
                          </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'avaliacoes',
      list: [],
      selecionados: [],
      page: 1,
      perPage: 10,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { skip: this.query.skip, limit: this.query.limit  });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async duplicar(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja duplicar esta avaliação ?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              const req = await this.$http.post(`/v1/${this.route}/duplicar`,{ avaliacao: id });
              if(!req.data.success) return this.$vToastify.error(req.data.err);
              this.$vToastify.success("Duplicado com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Arquivadas com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar essa avaliação ?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true  });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>